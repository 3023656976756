<script type="module" lang="ts">
	export let buttonText = 'Null';
	export let buttonTextColor = 'red-600';
	export let buttonDisabled = false;
	export let onClick: any = null;
	export let href: any = '';
	export let icon: string = null;
	export let iconAlt: string = null;
	export let iconStyle = '';
	export let buttonTextSize = 'md';
</script>

{#if buttonDisabled == false}
	<a {onClick} disabled={false} {href} class=""
		><p class="text-md text-{buttonTextColor} font-semibold text-{buttonTextSize}">
			{buttonText}
			{#if icon}<img
					style={iconStyle}
					class="-mt-0.5 ml-2 inline h-6 w-6 fill-red-600"
					alt={iconAlt}
					src={icon}
				/>{/if}
		</p></a
	>
{:else if buttonDisabled == true}
	<a {onClick} disabled={true} {href} class=""
		><p class="text-md text-{buttonTextColor} font-semibold">
			{buttonText}
			{#if icon}<img style={iconStyle} class="ml-2 inline h-6 w-6" alt={iconAlt} src={icon} />{/if}
		</p></a
	>
{/if}
